import React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import InstagramAuth from '../../components/Admin/InstagramAuth';
import styles from './styles.module.less';
import { themeOptions } from '../../components/shared/themeOptions';

const theme = createMuiTheme(themeOptions);

export default props => (
   <MuiThemeProvider theme={theme}>
      <div class={styles.Instagram}>
         <InstagramAuth />
      </div>
   </MuiThemeProvider>
);
